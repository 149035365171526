import { config } from "../config";

const Footer = ({ hasMenu = true }) => (
    <footer className="footer">
        { hasMenu ? <nav className="menu">
            <a href={config.routing.contacts} className="link">CONTACT US</a>
            <a href={config.routing.privacy} className="link">PRIVACY POLICY</a>
            <a href={config.routing.terms} className="link">TERMS AND CONDITIONS</a>
        </nav> : '' }
        <div className="social-hint">Follow us on:</div>
        <div className="socials">
            <a href="https://www.youtube.com/itsbrandontv" className="link youtube" rel="noreferrer" target="_blank">Brandon TV Youtube link</a>
            <a href="https://www.facebook.com/itsbrandontv" className="link facebook" rel="noreferrer" target="_blank">Brandon TV Facebook link</a>
            <a href="https://www.instagram.com/itsbrandontv/" className="link instagram" rel="noreferrer" target="_blank">Brandon TV Instagram link</a>
            <a href="https://twitter.com/itsbrandontv" className="link twitter" rel="noreferrer" target="_blank">Brandon TV Twitter link</a>
            <a href="https://www.tiktok.com/@itsbrandontv" className="link tiktok" rel="noreferrer" target="_blank">Brandon TV Tiktok link</a>
        </div>
        <div className="copyrights">BTV &copy; { new Date().getFullYear() }</div>
    </footer>
)

export default Footer
