export const config = {
    routing: {
        terms: '/terms',
        privacy: '/privacy-policy',
        helpCenter: '/faq',
        contacts: '/contacts',
    },
    links: {
        stream: 'https://itsbrandontv.live/',
        streamRegistration: 'https://itsbrandontv.live/auth/registration',
        blog: 'https://www.obsessedweekly.com/',
    },
    stores: {
        apple: 'https://apps.apple.com/app/brandon-tv/id6457257078',
        google: 'https://play.google.com/store/apps/details?id=brandontv.app',
        googleTv: 'https://play.google.com/store/apps/details?id=brandontv.app',
        roku: 'https://channelstore.roku.com/details/1dad50a3ae85c2e4013b0b732efa7cfe/brandontv',
        web: 'https://itsbrandontv.live/',
    },
    braze: {
        apiKey: 'bd1027b8-ea58-4485-b6aa-e4e8d59cee18', // brandon web
        baseUrl: 'sdk.iad-05.braze.com'
    },
    supportEmail: 'support@brandon.studio'
}
