import { config } from "../../config";

const PrivacyContent = () => (
    <div className="main-container terms-content">
        <div className="title">
            PRIVACY POLICY
            <div className="sub-header">Last Updated: September 26, 2023</div>
        </div>
        <section className="terms-section">
            <div className="title">Purpose of this Privacy Policy.</div>
            <div className="content">
                Pardon Us LLC dba Brandon TV (“We,” “us,” “our”) is committed to respecting and protecting your privacy rights, and we created this Privacy Policy to give you (the user) notice of how your Personally Identifiable Information will (and will not) be collected, used and shared by us in connection with any mobile apps and other websites or mobile apps where this appears and any website(s) (together with its subdomains, content and services) (collectively, the “Platforms”). For purposes of this Privacy Policy, “Personally Identifiable Information” (sometimes referred to as “your information” or “information about you”), in general, is data that identifies you or makes you identifiable. It may also include information or data that can be used to identify, locate, track or contact you. Examples of Personally Identifiable Information include but are not limited to information such as your name, email address, telephone number, postal address, IP address, and geo-location information. Information that is de-identified, aggregated or anonymized is non-Personally Identifiable Information and is not subject to this Privacy Policy. In simplified form (greater detail is below), our Privacy Policy can be summarized as follows:
                <ol>
                    <li>We may collect some Personally Identifiable Information from and about you, but you control how much you share about yourself.
                    </li>
                    <li>We do not collect Personally Identifiable Information from children under 13 years of age.</li>
                    <li>We take your information seriously.</li>
                    <li>We will not without your consent sell or rent your information but may share with our vendors, suppliers, clients and contributors in connection with the services you are seeking.
                    </li>
                    <li>If we use a third party to assist us, they will be bound to protect your information.</li>
                    <li>We may aggregate Personally Identifiable Information.</li>
                    <li> In certain legal situations, we may be compelled to disclose your information, but we will let you know if that occurs.
                    </li>
                    <li>If you are outside the United States, you understand and agree that we may transfer  and store your information in the United States.
                    </li>
                </ol>
                This Privacy Policy applies to the Platforms where it appears, and describes how we treat Personally Identifiable Information on our Platforms. By interacting with us, you consent to these practices.
                <br/><br/>
                We collect Personally Identifiable Information from and about you. We may collect contact and other Personally Identifiable Information on our Platforms. We may collect your name, organization (if any), phone number, email and mailing addresses  if you register in any fashion, submit an inquiry or sign up for a newsletter. We may collect information about your device, computer and Internet usage. If you use the Platforms on a mobile device, we might collect information from your phone. We may also collect information about the parts of our Platforms you use or third-party apps or websites you visit when you leave our Platforms. We may work with third parties who collect information about you when you use our Platforms. We may collect other Personally Identifiable Information. For example, we may collect your location using GPS, a cellular network location, Wi-Fi networks, browser services, or information you provide. We might look at how often you use the Platforms.
                <br/><br/>
                We collect Personally Identifiable Information in different ways. We may collect this information directly from you. For example, we collect Personally Identifiable Information if you register on the Platforms.  We may collect Personally Identifiable Information from you passively. For example, we may collect information from your phone, like your UDID. Our vendors might also use scripts or other tools to track your activities on our Platforms. They may do this to make sure our Platforms function properly.  We may get information about you from third parties. For example, our affiliates or partners or clients may give us information about you. If our Platforms have social media integration, social media platforms may also give us information about you.
                <br/><br/>
                <b>We combine Personally Identifiable Information:</b><br/>
                For example, we may combine information about you that we have collected offline with information about you we collect online. Or we may combine information about you we collect online with information about you we collected separately. We may also combine information we collect from you with information about you we receive from third parties. We use your information as disclosed and described below.
                <br/><br/>
                <b>We use Personally Identifiable Information to administer the Platforms:</b><br/>
                We use your information to respond to your requests or questions. For example, we might use your information to respond to your customer feedback, and to communicate with you about your account or our relationship. We may contact you about your account or feedback. We might also contact you about this Privacy Policy or our Terms of Service.
                <br/><br/>
                <b>We use Personally Identifiable Information to improve our products and services:</b><br/>
                We may use your information to make our Platforms and products better. We might use your information to customize your experience with us.
                <br/><br/>
                <b>We use Personally Identifiable Information for security purposes:</b><br/>
                We may use your information to protect our company, our customers, our clients and our Platforms.
                <br/><br/>
                <b>We use Personally Identifiable Information for marketing purposes:</b><br/>
                For example, we might provide you with information about new products and special offers from us. We might also use your information to serve you ads about products and offers. We might tell you about new features or updates. These might be third party offers or products we think you might find interesting. To manage this, read the choices section below.
                <br/><br/>
                <b>We use Personally Identifiable Information as otherwise permitted by law.</b><br/>
                We may share your information with third parties as outlined herein, including our clients who provide services and products to you. We will share your information with our vendors. These are third parties who perform services on our behalf. For example, we may share your information with vendors who send emails for us. We will not separately rent or sell your mobile telephone number to other companies or individuals unless we have your consent. We may use or disclose your information in any of the following limited circumstances:
                <ul>
                    <li>To provide you with products or services.</li>
                    <li>We have your consent.</li>
                    <li>We need to enforce our Terms of Service.</li>
                    <li>We provide your information to a company controlled by, under common control with, or affiliated with us  for any purpose permitted by this Privacy Policy.
                    </li>
                    <li>We respond to subpoenas, court orders, or legal process, or to establish or exercise our legal rights or defend against legal claims.
                    </li>
                    <li>We believe it is necessary to share your information in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of our Terms of Service, or as otherwise required by law.
                    </li>
                    <li>We transfer Personally Identifiable Information about you if we, or our assets are, acquired by or merged with another company.
                    </li>
                </ul>

                <b>We will share Personally Identifiable Information if we think we have to in order to comply with the law or to protect ourselves or for business purposes:</b><br/>
                For example, we will share your information to respond to a court order or subpoena. We may share your information if a government agency or investigatory body requests, and when we are investigating potential fraud. We may share your information with any successor to all or part of our business. For example, if part of our business was sold, we may share your information as part of that transaction. We may share your information for other reasons we may describe to you, including with those with whom you have authorized us to do so.
                <br/><br/>
                <b>You have certain choices about sharing and marketing practices:</b><br/>
                You can opt out of receiving our marketing emails. To stop receiving our promotional emails, follow the instructions and click on the “unsubscribe” link in any promotional message you get from us. Even if you opt out of getting marketing messages, we will still send you transactional messages. These include responses to your questions.
                <br/><br/>
                <b>You can control cookies and tracking tools:</b><br/>
                We and third-party providers acting on our behalf, such as Google, may use a variety of online tools and tracking devices, such as cookies, web beacons and other storage technologies, to collect or receive information and track activity when you visit or use our Platforms and elsewhere on the internet.  We may use this information to provide measurement services and to serve you targeted ads. You may opt-out of the collection and use of information for ad targeting. To understand your options and how to exercise them, you can go to: <a href="http://www.aboutads.info/choices" target="_blank"
                   rel="noreferrer">http://www.aboutads.info/choices</a> and <a href="http://www.youronlinechoices.eu/" target="_blank"
                       rel="noreferrer">http://www.youronlinechoices.eu/</a>.
                <br/><br/>
                Your browser may give you the ability to control cookies or other tracking tools.  Because “cookies” help to enable important aspects of the user experience, we advise our clients to leave them turned on. How you do so depends on the type of tool.  Certain browsers can be set to reject browser cookies.  To control flash cookies, which we may use on certain websites from time to time, you can go to <a href="https://optout.aboutads.info/" target="_blank"
                   rel="noreferrer">https://optout.aboutads.info/</a>.  Why?  Because flash cookies cannot be controlled through your browser settings. By using our Platforms, you are consenting to the use of cookies as described in this cookie policy.
                <b>Our Do Not Track Policy:</b><br/>
                Some browsers have Do Not Track features that allow you to tell a website not to track you. These features are not all uniform. We do not currently respond to those signals.  If you block cookies, certain features on our Platforms may not work.  If you block or reject cookies, not all of the tracking described here will stop.
                <br/><br/>
                <b>Mobile Control:</b><br/>
                Most mobile devices provide users with the ability to disable location services. Most likely, these controls are located in the device's settings menu. If you have questions about how to disable your device's location services, we recommend you contact your mobile service carrier or your device manufacturer.
                <br/><br/>
                <b>Information You Can Access:</b><br/>
                Examples of information you can access easily on our Platform include up-to-date information regarding recent viewing history; personally identifiable information (including name, e-mail, password, communications and personalized advertising preferences); payment settings (including credit card information and promotional certificate and gift card balances); e-mail notification settings; Recommendations (including Recommended for You and Improve Your Recommendations); and Your Profile (including your ratings and reviews).
                <br/><br/>
                <b>Our Platforms are meant for adults:</b><br/>
                Our Platforms are not intended for children. We do not knowingly collect or sell Personal Information from children under 13 years of age without permission from a parent or guardian. If you are a parent or legal guardian and think your child under age 13 has given us information, you can email us at <a href={`mailto:${config.supportEmail}`} target="_blank" rel="noreferrer">{ config.supportEmail }</a>.
                Please mark your inquiries COPPA Information Request. Parents, you can learn more about how to protect children's privacy online at <a href="https://www.consumer.ftc.gov/articles/0031-protecting-your-childs-privacy-online."
                   target="_blank"
                   rel="noreferrer">https://www.consumer.ftc.gov/articles/0031-protecting-your-childs-privacy-online.</a>.
                <br/><br/>
                <b>We store information in the United States:</b><br/>
                Information we maintain may be stored both within and outside of the United States.  If you live outside of the United States, you understand and agree that we may transfer your information to the United States. This site is subject to U.S. laws, which may not afford the same level of protection as those in your country.
                <br/><br/>
                <b>We use standard security measures:</b><br/>
                The Internet is not 100% secure. While we take reasonable precautions to protect your information, we cannot promise that your use of our Platforms will be completely safe. We encourage you to use caution when using the Internet. This includes not sharing your passwords.  To learn more about how to protect yourself online, visit www.ftc.gov. We keep Personally Identifiable Information as long as it is necessary or relevant for the practices described in this Privacy Policy. We also keep your information as otherwise required by law.
                <br/><br/>
                <b>We may link to other sites or have third party services on our Platforms we do not control:</b><br/>
                Our Platforms may have links to websites we do not control. Our Platforms may also have plugins or host other third-party services that we do not control. This Privacy Policy does not apply to the privacy practices of those websites or third parties. When you interact with those websites or third parties, they may collect information from you.  Read the privacy policy of other websites carefully. We are not responsible for these third party practices.
                <br/><br/>
                <b>Shine the Light</b><br/><br/>
                If you reside in California, you have the right to ask us one time each year if we have shared personal information with third parties for their direct marketing purposes. To make a request, please send us an email at <a href={`mailto:${config.supportEmail}`} target="_blank" rel="noreferrer">{ config.supportEmail }</a>.
                Indicate in your letter that you are a California resident making a Shine the Light inquiry.
                <br/><br/>
                <b>Feel free to contact us if you have more questions:</b><br/>
                If you have any questions or concerns about this Privacy Policy, please email us at <a href={`mailto:${config.supportEmail}`} target="_blank" rel="noreferrer">{config.supportEmail}</a>.
                <br/><br/>
                <b>We may update this Privacy Policy:</b><br/>
                This Privacy Policy may be updated from time to time, but the changes will not apply retroactively. We will notify you of any material changes to our Privacy Policy as required by law. We will also post an updated copy on our Platforms.  Your continued use of our Platforms indicates your consent to the Privacy Policy then posted. Please check our Platforms periodically for updates.<br/><br/>
                BrandonTV’s use and transfer of information received from Google APIs to any other app will adhere to <a
                href="https://developers.google.com/terms/api-services-user-data-policy" target="_blank" rel="noreferrer">Google API Services User Data Policy</a>, including the Limited Use requirements.
            </div>
        </section>
    </div>
)

export default PrivacyContent
