import { config } from "../config";

const storeLinks = [
    { name: 'Apple Store', icon: 'icon-store-apple', link: config.stores.apple },
    { name: 'Google Play', icon: 'icon-store-google', link: config.stores.google },
    { name: 'Roku Store', icon: 'icon-store-roku', link: config.stores.roku },
]

const Stores = () => (
    <div className="stores">
        { storeLinks.map((store, idx) => (
            <a href={store.link}
               className={`icon ${store.icon}`}
               target="_blank"
               rel="noreferrer"
               key={`store-link-${idx}`}
            >{ store.name }</a>
        )) }
    </div>
)

export default Stores
