import Stores from "../../components/Stores";

const PromoBanner = () => (
    <div className="promo-banner-container">
        <div className="content">
            <div className="sub-title">BTV AVAILABLE ON MULTIPLE DEVICES</div>
            <div className="title">
                WATCH US ANYWHERE AND AT<br/>
                <span className="emphase">ANYTIME</span>
            </div>
            <div className="description">
                No television provider needed.<br/>
                Watch new weekly episodes, and speak your mind anytime.
            </div>
            <Stores/>
        </div>
    </div>
)

export default PromoBanner
