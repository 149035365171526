import Header from "../../components/Header";
import Footer from "../../components/Footer";
import PrivacyContent from "./PrivacyContent";
import useDocumentTitle from "../../hooks/useDocumentTitle";


const PrivacyPage = () => {
    useDocumentTitle('Privacy Policy')

    return (
        <div className="terms-page">
            <Header/>
            <PrivacyContent/>
            <Footer/>
        </div>
    )
}

export default PrivacyPage
