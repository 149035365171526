import Header from "../../components/Header";
import Footer from "../../components/Footer";
import TermsContent from "./TermsContent";
import useDocumentTitle from "../../hooks/useDocumentTitle";


const TermsPage = () => {
    useDocumentTitle('Terms Page')

    return (
        <div className="terms-page">
            <Header/>
            <TermsContent/>
            <Footer/>
        </div>
    )
}

export default TermsPage
