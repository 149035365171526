import React from 'react';
import ReactDOM from 'react-dom/client';
import './main.scss';
import MainPage from './pages/main/MainPage';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import TermsPage from "./pages/terms/TermsPage";
import { config } from "./config";
import PrivacyPage from "./pages/privacy/PrivacyPage";
import HelpCenterPage from './pages/helpCenter/HelpCenterPage';
import ContactsPage from './pages/contacts/ContactsPage';

const router = createBrowserRouter([
    { path: '/', element: <MainPage /> },
    { path: config.routing.terms, element: <TermsPage /> },
    { path: config.routing.privacy, element: <PrivacyPage /> },
    { path: config.routing.helpCenter, element: <HelpCenterPage /> },
    { path: config.routing.contacts, element: <ContactsPage /> },
])

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)
