import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { config } from "../../config";

const ContactsPage = () => {
  useDocumentTitle('Contacts')

  const contacts = [
    {title: 'CUSTOMER SERVICE', email: config.supportEmail},
    {title: 'CREATORS', email: 'info@brandon.studio'},
    {title: 'STUDIO', email: 'justin@brandon.studio'},
    {title: 'BRAND PARTNERSHIPS', email: 'sue@brandon.studio'},
    {title: 'MEDIA', email: 'pr@brandon.studio'},
  ];

  return (
    <div className="contacts-page">
        <Header isActive={'contacts'}/>
        <div className="main-container page-content">
          <div className="title">CONTACT US</div>
          <div className="contacts-section">
            {contacts.map((item, index) => (
              <>
                {index === 0 ? '' : <span className="separator"/>}
                <div key={index} className="contact-block">
                  <div className="title">{item.title}</div>
                  <a className="email" target="_blank" rel="noreferrer" href={`mailto:${item.email}`}>{item.email}</a>
                </div>
              </>
            ))}
          </div>
          <a className="contacts-btn" href={config.links.streamRegistration}>Join the BRANDON community</a>
        </div>
        <Footer/>
    </div>
  )
}

export default ContactsPage
