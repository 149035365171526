import MainScreen from "./MainScreen";
// import PromoCatalog from "./PromoCatalog";
import PromoBanner from "./PromoBanner";
// import LastCall from "./LastCall";
import Footer from "../../components/Footer";
import { config } from "../../config";
import * as braze from "@braze/web-sdk";
import Presale from "./Presale";
import { useLocation } from "react-router-dom";
import { useEffect, useRef } from "react";
import Header from "../../components/Header";

const COOKIE_PRESALE = 'presale-user'

function setCookie(value, days) {
    const expires = new Date(Date.now() + days * 24 * 60 * 60 * 1000).toUTCString();
    document.cookie = `${COOKIE_PRESALE}=${encodeURIComponent(value)}; expires=${expires}; path=/`;
}

function setPresaleUser() {
    setCookie("true", 365)
}

braze.initialize(config.braze.apiKey, { baseUrl: config.braze.baseUrl })
braze.openSession()

const MainPage = () => {
    const location = useLocation()
    let sectionHash = useRef('')
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    if (urlParams.get('registered')) {
        setPresaleUser()
    }
    useEffect(() => {
        if (location.hash) {
            sectionHash.current = location.hash.slice(1); // safe hash for further use after navigation
        }
        if (sectionHash.current && document.getElementById(sectionHash.current)) {
            setTimeout(() => {
                document
                    .getElementById(sectionHash.current)
                    ?.scrollIntoView({ behavior: 'smooth', block: 'start' })
                sectionHash.current = ''
            }, 100)
        }
    }, [location]);
    return (
        <>
            <Header noCircle={true} hasAuth={true}/>
            <MainScreen/>
            <Presale/>
            {/*<PromoCatalog/>*/}
            <PromoBanner/>
            {/*<LastCall/>*/}
            <Footer/>
        </>
    )
}

export default MainPage
