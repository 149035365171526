import { config } from "../../config";

const TermsContent = () => (
    <div className="main-container terms-content">
        <section className="terms-section">
            <div className="title">Brandon TV Privacy Policy Notice</div>
            <div className="content">
                THIS IS A LEGAL AGREEMENT BETWEEN YOU AND PARDON US, LLC DBA BRANDON TV STATING THE TERMS THAT GOVERN YOUR PARTICIPATION AS A USER. PLEASE READ THIS TERMS OF SERVICE AGREEMENT (“AGREEMENT”). BY PRESSING "AGREE," YOU ARE AGREEING TO BE BOUND BY THE TERMS OF THIS AGREEMENT. IF YOU DO NOT AGREE TO THE TERMS OF THIS AGREEMENT, PRESS "CANCEL"
                <br/><br/>
                <div className="title">Brandon TV Terms of Service
                    <div className="sub-header">Last Updated: September 26, 2023</div>
                </div>
                <br/>
                Welcome to the terms of service for Brandon TV. These terms are between you and Brandon TV ("Brandon TV", "we" or "us"). Please read these terms, along with the <a href="https://www.itsbrandontv.com/privacy-policy">Privacy Policy</a> and Conditions of Use applicable to you, and all other rules and policies related to the Brandon TV service (including, but not limited to, any rules or usage provisions specified on any product detail page or on any help or other informational page for the Brandon TV service) (collectively, this "Agreement"). Each time you visit, browse, or use the Brandon TV service, you accept this Agreement.
                <br/><br/>
                <ol>
                    <li><b>THE SERVICE</b>
                    <br/><br/>
                    Brandon TV (the "Service") is a personalized service that offers, recommends and helps you discover and watch the various programs and content of Brandon TV (collectively, "Digital Content") and other services as provided in this Agreement. You can access the Brandon TV service and Digital Content through the Brandon TV mobile application. If you are under 18 years of age, or the age of majority in your location, you may use the Brandon TV service only with involvement of a parent or guardian. We personalize content and features as part of the services, including showing you recommendations on Digital Content, features and services that might be of interest to you. We also endeavor to continuously improve Brandon TV services and your experience with them.</li>
                    <br/>
                    <li><b>COMPATIBLE DEVICES</b>
                    <br/><br/>
                    In order to stream the Digital Content, you will need to use a mobile device, personal computer, or other device that meets the system and compatibility requirements that we establish from time to time (a "Compatible Device"). We may change the requirements for Compatible Devices from time to time and, in some cases, whether a device is (or remains) a Compatible Device may depend on software or systems provided or maintained by the device manufacturer or other third parties. Accordingly, devices that are Compatible Devices at one time may cease to be Compatible Devices in the future.</li>
                    <br/>
                    <li><b>GEOGRAPHIC VARIABILITY</b>
                    <br/><br/>
                    Due to technical and other restrictions imposed by content providers, the Brandon TV service is available only in certain locations. The Digital Content (including subtitled and dubbed audio versions of Digital Content) and how we offer Digital Content to you will vary over time and by location. Brandon TV will use technologies to verify your geographic location. You may not use any technology or technique to obscure or disguise your location.</li>
                    <br/>
                    <li><b> DIGITAL CONTENT</b>
                    <br/><br/>
                        <ol type="a">
                            <li><b>General.</b> The Brandon TV service may allow you to: (i) access Digital Content on a subscription basis for viewing during a limited period of time during a subscription period ("Subscription Digital Content"), (ii) rent Digital Content for on-demand viewing over a limited period of time ("Rental Digital Content"), and/or (iii) access Digital Content on an ad-supported or promotional basis for viewing over a limited period of time ("Ad-Supported Digital Content"). Digital Content may be available as Subscription Digital Content, Ad-Supported Digital Content, or any combination of those, and in each case is subject to the limited license grant below.</li>
                            <br/>
                            <li><b>Subscriptions/Memberships.</b> Offers and pricing for subscriptions (also referred to at times as memberships), the subscription services, the extent of available Subscription Digital Content, and the specific titles available through subscription services, may change over time and by location without notice (except as may be required by applicable law). Unless otherwise indicated, any price changes are effective as of the beginning of the next subscription period. If you do not agree to a subscription change, you may cancel your subscription per Section 4(c) below. The pricing for subscriptions or memberships may include sales tax, VAT and/or other taxes. Where applicable, such taxes will be collected by the party with whom you transact for the service, which may be Brandon TV or a third party. We make no guarantee as to the availability of specific Subscription Digital Content or the minimum amount of Subscription Digital Content available in any subscription.</li>
                            <br/>
                            <li><b>Cancellation of Subscriptions/Memberships</b> You may cancel a subscription or membership service any time by visiting Your Account and adjusting your membership settings, or by contacting Brandon TV customer service at <a href={`mailto:${config.supportEmail}`} target="_blank" rel="noreferrer">{ config.supportEmail }</a>.<br/><br/>
                                For customers in the European Union, if you cancel within 14 days of receiving confirmation of your subscription or membership service, we will refund your full membership fee; except that we may charge you (or withhold from your refund) the value of the Brandon TV service used through your account during such period. You also expressly accept that the Brandon TV service will start within your cancellation period. If you cancel at any other time, we will refund your full membership fee only if Digital Content available as part of your membership has not been accessed through your account since your latest membership charge.</li>
                            <br/>
                            <li><b>Payment Methods.</b> If we are unable to process your payment using your designated payment method, we reserve the right to charge any payment method we have on file for you. If you purchase a subscription, your subscription will automatically continue, and you authorize us (without further notice, unless required by applicable law) to collect the then-applicable periodic subscription fee including any taxes, using any payment method we have on file for you. We will continue to bill you for the relevant subscription service until canceled. If all payment methods we have on file for you are declined for payment of your subscription fee, your subscription will be canceled unless you provide us with a new payment method. If you provide us with a new payment method and are successfully charged before your subscription is canceled, your new subscription period will be based on the original billing date and not the date of the successful charge. You can use "Your Account" settings to update your designated payment method(s)</li>
                            <br/>
                            <li><b>Promotional Trials.</b> We may sometimes offer eligible customers various trial or other promotional memberships, which are subject to this Agreement except as otherwise stated in the promotional offers. We reserve the right, in our sole discretion, to determine your eligibility. Trial members may at any time (through Your Account) choose not to continue to paid membership at the end of the trial period.</li>
                            <br/>
                            <li>
                                <b>Limited License to Digital Content.</b> Subject to payment of any charges to access Digital Content, and your compliance with all terms of this Agreement, Brandon TV grants you a non-exclusive, non-transferable, non-sublicensable, limited license, during the applicable Viewing Period, to access and view the Digital Content for personal, non-commercial, private use. We may automatically remove Digital Content from your Compatible Device after the end of its Viewing Period.<br/><br/>
                                BTV may remove your access to the Digital Content at any time, if the content is being screen recorded through devices registered to your account.<br/><br/>
                                BTV may remove your access to the Digital Content at any time, if your profile is being shared between more than 3 devices or watched simultaneously in different locations.
                            </li>
                            <br/>
                            <li><b>Playback Quality; Streaming.</b> The playback resolution and quality of the Digital Content you receive will depend on a number of factors, including the type of Compatible Device on which you are accessing the Digital Content and your bandwidth, which may increase or decrease over the course of your viewing. If we detect that Digital Content we are streaming to you may be interrupted or may otherwise not play properly due to bandwidth constraints or other factors, we may decrease the resolution and file size of the streamed Digital Content in an effort to provide an uninterrupted viewing experience. While we strive to provide you a high quality viewing experience, we make no guarantee as to the resolution or quality of the Digital Content you will receive when streaming, even if you have paid extra for access to high definition, ultra-high definition, or high-dynamic-range content.</li>
                            <br/>
                            <li><b>General Restrictions.</b> You may not (i) transfer, copy or display the Digital Content, except as permitted in this Agreement; (ii) sell, rent, lease, distribute, or broadcast any right to the Digital Content; (iii) remove any proprietary notices or labels on the Digital Content; (iv) attempt to disable, bypass, modify, defeat, or otherwise circumvent any digital rights management or other content protection system used as part of the Service; or (v) use the Brandon TV service or Digital Content for any commercial or illegal purpose.</li>
                        </ol>
                    </li>
                    <br/>
                    <li><b>SOFTWARE</b>
                    <br/><br/>
                    <ol type="a">
                        <li><b>Use of the Software.</b> We may make available software for your use in connection with the Brandon TV service ("Software").</li>
                        <br/>
                        <li><b>Information Provided to Brandon TV.</b> The Brandon TV service and Software may provide Brandon TV with information relating to your use and the performance of the Brandon TV service and Software, as well as information regarding the devices on which you download and use the Brandon TV service and Software. For example, the Software may provide Brandon TV with information related to the Digital Content that you stream and your use of that Digital Content (such as whether and when you viewed the Digital Content, which may, among other things, help us measure the Viewing Period for Rental Digital Content). Any information we receive is subject to the Brandon TV Privacy Policy.</li>
                    </ol>
                    </li>
                    <br/>
                    <li><b>ADDITIONAL TERMS</b>
                    <br/><br/>
                    <ol type="a">
                        <li><b>Termination.</b> We may terminate your access to the Brandon TV service, including any subscription available as part of the Brandon TV service, at our discretion without notice (except as may be required by applicable law). If we do so, we will give you a pro-rated refund of your subscription fee (if any); however, if you violate any of the terms of this Agreement, your rights under this Agreement will automatically terminate without notice, and Brandon TV may, in its discretion, immediately revoke your access to the Brandon TV service and to Digital Content without refund of any fees.</li>
                        <br/>
                        <li><b>Explicit Content.</b> By using the Brandon TV service, you may encounter content that may be offensive, indecent or objectionable; this content may or may not be identified as having explicit language or other attributes. Nevertheless, you agree to use the Brandon TV service at your sole risk, and Brandon TV has no liability to you for any content. Content types, genres, categories, and descriptions are provided for convenience, and Brandon TV does not guarantee their accuracy.</li>
                        <br/>
                        <li><b>Communications.</b> We may send you promotions or otherwise communicate with you electronically, which may include email, push notification, sms text messages and other third party services, and you hereby consent to receive those communications (unless you are a customer in the European Union, in which case the preceding sentence does not apply). These communications will be in accordance with the Brandon TV Privacy Policy. To stop receiving marketing communications from Brandon TV, you will need to unsubscribe from the communications service through a button that can be found in the email, through your phone settings, or other relevant setting method.</li>
                        <br/>
                        <li><b>Modification of Brandon TV service.</b> Brandon TV reserves the right to modify, suspend, or discontinue the Brandon TV service, or any part of the Brandon TV service, at any time and without notice (except as required by applicable law), and Brandon TV will not be liable to you should it exercise such rights, even if your ability to use Digital Content is impacted by the change.</li>
                        <br/>
                        <li><b>Amendments.</b> Brandon TV reserves the right to make changes to this Agreement at any time by posting the revised terms in connection with the Brandon TV service. To the maximum extent permitted by law, your continued use of the Brandon TV service or Software following any changes will constitute your acceptance of such changes. However, any increase in subscription fee will not apply until your subscription is renewed.</li>
                        <br/>
                        <li><b>Reservation of Rights; Waiver.</b> The Brandon TV service, Software and the Digital Content embody intellectual property that is protected by law. Copyright owners of Digital Content are intended third-party beneficiaries under the Agreement. Our failure to insist upon or enforce your strict compliance with the Agreement will not constitute a waiver of any of our rights.</li>
                        <br/>
                        <li><b>Disputes/Conditions of Use. Any dispute or claim arising from or relating to this Agreement or the Brandon TV service is subject to the governing law, disclaimer of warranties and limitation of liability, any binding arbitration, and all other terms in the Brandon TV Conditions of Use. You agree to those terms by using the Service. YOU MAY ALSO BE ENTITLED TO CERTAIN CONSUMER PROTECTION RIGHTS UNDER THE LAWS OF YOUR LOCAL JURISDICTION.</b></li>
                        <br/>
                        <li><b>Limitation of Liability.</b> Without limiting the disclaimer of warranties and limitation of liability in the Brandon TV Conditions of Use: (i) in no event shall our or our software licensors' total liability to you for all damages arising out of or related to your use or inability to use the Software exceed the amount of fifty dollars ($50.00); and (ii) in no event shall our or our Digital Content providers' total liability to you for all damages arising from your use of the Brandon TV service, the Digital Content, or information, materials or products included on or otherwise made available to you through the Service, exceed the amount you paid to us over the past 12 months to view the Digital Content related to your claim for damages. The limitations in this section will apply to you even if the remedies fail of their essential purpose.
                        <br/><br/>
                        <b>CERTAIN JURISDICTIONS, INCLUDING JURISDICTIONS IN THE EUROPEAN UNION, DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR CERTAIN TYPES OF DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.</b>
                        </li>
                        <br/>
                        <li><b>Contact Information.</b> For communications concerning this Agreement, please write to Brandon TV at: 2525 N. Naomi St., Burbank, CA 91504 USA; or email to: <a
                            href={`mailto:${config.supportEmail}`} target="_blank"
                            rel="noreferrer">{config.supportEmail}</a></li>
                        <br/>
                        <li><b>Severability.</b> If any term or condition of this Agreement is deemed invalid, void, or for any reason unenforceable, that part will be deemed severable and will not affect the validity and enforceability of any remaining term or condition.</li>
                    </ol>
                    </li>
                </ol>
                <br/>
                <div className="title">Conditions of Use
                    <div className="sub-header">Last Updated: September 26, 2023</div>
                </div>
                <br/>
                Welcome to Brandon TV which provides digital content and features to you when you visit or use Brandon TV services, use Brandon TV applications for mobile, or use software provided by Brandon TV in connection with any of the foregoing (collectively, "Services"). Brandon TV provides the Services subject to the following conditions.
                <br/><br/>
                <b>By using the Services, you agree to these conditions. Please read them carefully.</b>
                <br/>
                We offer a wide range of Services, and sometimes additional terms may apply. When you use the Service, you also will be subject to the guidelines, terms and agreements applicable to that Service ("Service Terms"). If these Conditions of Use are inconsistent with the Service Terms, those Service Terms will control.
                <br/><br/>
                <b>PRIVACY</b>
                <br/>
                Please review our Privacy Policy, which also governs your use of Services, to understand our practices.
                <br/><br/>
                <b>ELECTRONIC COMMUNICATIONS</b>
                <br/>
                When you use Services, or send e-mails, text messages, and other communications from your desktop or mobile device to us, you may be communicating with us electronically. You consent to receive communications from us electronically, such as e-mails, texts, mobile push notices, or notices and messages on this site or through the other Services, and you can retain copies of these communications for your records. You agree that all agreements, notices, disclosures, and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing.
                <br/><br/>
                <b>COPYRIGHT</b>
                <br/>
                All content included in or made available through any Service, such as text, graphics, logos, button icons, images, audio clips, digital content, data compilations, and software is the property of Brandon TV or its content suppliers and protected by United States and international copyright laws. The compilation of all content included in or made available through any Service is the exclusive property of Brandon TV and protected by U.S. and international copyright laws.
                <br/><br/>
                <b>TRADEMARKS</b>
                <br/>
                In addition, graphics, logos, page headers, button icons, scripts, and service names included in or made available through any Service are trademarks or trade dress of Brandon TV in the U.S. and other countries. Brandon TV’s trademarks and trade dress may not be used in connection with any product or service that is not Brandon TV’s, in any manner that is likely to cause confusion among users, or in any manner that disparages or discredits Brandon TV. All other trademarks not owned by Brandon TV that appear in any Service are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by Brandon TV.
                <br/><br/>
                <b>PATENTS</b>
                <br/>
                One or more patents owned by Brandon TV may apply to the Services and to the features and services accessible via the Services. Portions of the Services may operate under license of one or more patents.
                <br/><br/>
                <b>LICENSE AND ACCESS</b>
                <br/>
                Subject to your compliance with these Conditions of Use and any Service Terms, and your payment of any applicable fees, Brandon TV or its content providers grant you a limited, non-exclusive, non-transferable, non-sublicensable license to access and make personal and non-commercial use of the Services. This license does not include any resale or commercial use of any Service, or its contents; any collection and use of any digital content; any derivative use of the Service or its contents; any downloading, copying, or other use of account information for the benefit of any third party; or any use of data mining, robots, or similar data gathering and extraction tools. All rights not expressly granted to you in these Conditions of Use or any Service Terms are reserved and retained by Brandon TV or its licensors, suppliers, publishers, rightsholders, or other content providers. No Service, nor any part of any Service, may be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial purpose without express written consent of Brandon TV. You may not frame or utilize framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of Brandon TV without express written consent. You may not use any meta tags or any other "hidden text" utilizing Brandon TV’s name or trademarks without the express written consent of Brandon TV. You may not misuse the Services. You may use the Services only as permitted by law. The licenses granted by Brandon TV terminate if you do not comply with these Conditions of Use or any Service Terms.
                <br/><br/>
                <b>YOUR ACCOUNT</b>
                <br/>
                You need your own Brandon TV account to use the Services, and you may be required to be logged in to the account and have a valid payment method associated with it. If there is a problem charging your selected payment method, we may charge any other valid payment method associated with your account. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your account, and you agree to accept responsibility for all activities that occur under your account or password. Brandon TV reserves the right to refuse service, terminate accounts, terminate your rights to use the Services, remove or edit content, or cancel service in its sole discretion.
                <br/><br/>
                <b>REVIEWS, COMMENTS, COMMUNICATIONS, AND OTHER CONTENT</b>
                <br/>
                You may post reviews, comments, ratings, photos, videos, and other content; send communications; and submit suggestions, ideas, comments, questions, or other information, so long as the content is not illegal, obscene, threatening, defamatory, invasive of privacy, infringing of intellectual property rights (including publicity rights), or otherwise injurious to third parties or objectionable, and does not consist of or contain software viruses, political campaigning, commercial solicitation, chain letters, mass mailings, or any form of "spam" or unsolicited commercial electronic messages. You may not use a false e-mail address, impersonate any person or entity, or otherwise mislead as to the origin of a card or other content. Brandon TV reserves the right (but not the obligation) to remove or edit such content, but does not regularly review posted content.
                <br/><br/>
                If you do post content or submit material, and unless we indicate otherwise, you grant Brandon TV a nonexclusive, royalty-free, perpetual, irrevocable, and fully sublicensable right to use, reproduce, modify, adapt, publish, perform, translate, create derivative works from, distribute, and display such content throughout the world in any media now known or hereafter devised. You grant Brandon TV and sublicensees the right to use the name that you submit in connection with such content, if they choose. You represent and warrant that you own or otherwise control all of the rights to the content that you post; that the content is accurate; that use of the content you supply does not violate this policy and will not cause injury to any person or entity; and that you will indemnify Brandon TV for all claims resulting from content you supply. Brandon TV has the right but not the obligation to monitor and edit or remove any activity or content. Brandon TV takes no responsibility and assumes no liability for any content posted by you or any third party.
                <br/><br/>
                <b>INTELLECTUAL PROPERTY COMPLAINTS</b>
                <br/>
                Brandon TV respects the intellectual property of others. If you believe that your intellectual property rights are being infringed, please contact us immediately and file a claim for copyright infringement.
                <br/><br/>
                <b>DIGITAL CONTENT DESCRIPTIONS</b>
                <br/>
                Brandon TV attempts to be as accurate as possible. However, Brandon TV does not warrant that digital content descriptions or other content of any Service is accurate, complete, reliable, current, or error-free.
                <br/><br/>
                <b>APP PERMISSIONS</b>
                <br/>
                When you use apps created by A Brandon TV, such as the Brandon TV App, you may grant certain permissions to us for your device. Most mobile devices provide you with information about these permissions.
                <br/><br/>
                <b>DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</b>
                <br/>
                THE SERVICES AND ALL INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) AND OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE SERVICES ARE PROVIDED BY BRANDON TV ON AN "AS IS" AND "AS AVAILABLE" BASIS, UNLESS OTHERWISE SPECIFIED IN WRITING. BRANDON TV MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THE SERVICES, OR THE INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE SERVICES, UNLESS OTHERWISE SPECIFIED IN WRITING. YOU EXPRESSLY AGREE THAT YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK.
                <br/><br/>
                TO THE FULL EXTENT PERMISSIBLE BY LAW, BRANDON TV DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. BRANDON TV DOES NOT WARRANT THAT THE SERVICES, INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE SERVICES, OUR SERVERS OR ELECTRONIC COMMUNICATIONS SENT FROM BRANDON TV ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. TO THE FULL EXTENT PERMISSIBLE BY LAW, BRANDON TV WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF ANY SERVICE, OR FROM ANY INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH ANY SERVICE, INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL DAMAGES, UNLESS OTHERWISE SPECIFIED IN WRITING.
                <br/><br/>
                <b>DISPUTES</b>
                <br/>
                Any dispute or claim relating in any way to your use of any Service, or to any products or services sold or distributed by Brandon TV will be resolved by binding arbitration, rather than in court, except that you may assert claims in small claims court if your claims qualify. The Federal Arbitration Act and federal arbitration law apply to this agreement.
                <br/><br/>
                There is no judge or jury in arbitration, and court review of an arbitration award is limited. However, an arbitrator can award on an individual basis the same damages and relief as a court (including injunctive and declaratory relief or statutory damages), and must follow the terms of these Conditions of Use as a court would.
                <br/><br/>
                Any dispute, effort, or proceeding of any nature whatsoever to enforce, challenge or interpret any provision of this agreement shall, at the request of either party, be subject to confidential mediation before JAMS under the then-current JAMS International Mediation Rules & Procedures, except as provided otherwise in this paragraph. If mediation is not successful, such dispute, effort or proceeding shall be resolved through a confidential arbitration before JAMS under the then-current JAMS Comprehensive Arbitration Rules & Procedures, except as provided otherwise in this paragraph. The arbitrator shall award attorneys’ fees and costs to the prevailing party. Any such mediation or arbitration shall take place in Los Angeles, California, or such other location as the parties may agree
                <br/><br/>
                We each agree that any dispute resolution proceedings will be conducted only on an individual basis and not in a class, consolidated or representative action. If for any reason a claim proceeds in court rather than in arbitration we each waive any right to a jury trial. We also both agree that you or we may bring suit in court to enjoin infringement or other misuse of intellectual property rights.
                <br/><br/>
                <b>APPLICABLE LAW</b>
                <br/>
                By using any Service, you agree that the Federal Arbitration Act, applicable federal law, and the laws of the state of California, without regard to principles of conflict of laws, will govern these Conditions of Use and any dispute of any sort that might arise between you and Brandon TV.
                <br/><br/>
                <b>SITE POLICIES, MODIFICATION, AND SEVERABILITY</b>
                <br/>
                Please review our other policies. These policies also govern your use of the Services. We reserve the right to make changes to our site, policies, Service Terms, and these Conditions of Use at any time. If any of these conditions shall be deemed invalid, void, or for any reason unenforceable, that condition shall be deemed severable and shall not affect the validity and enforceability of any remaining condition.
                {/* Brandon TV knows that you care how information about you is used and shared, and we appreciate your
                trust that we will do so carefully and sensibly. This notice describes our privacy policy and how we use
                your personal information when you use <a
                href="https://www.itsbrandontv.com/">https://www.itsbrandontv.com/</a> (our “Site”). In this Privacy
                Notice we explain who we are, why and how we collect, store and use (collectively, “process” or
                “processing”) your
                personal information (also referred to as personal data), your rights and how to contact us.<br/><br/>
                We are the data controller - which means we decide how and why we use your personal information as set
                forth in this Privacy Notice.<br/><br/>
                <b>By using the service of Brandon TV, you acknowledge that you have read and understood this
                    privacy policy and are accepting the practices described in this Privacy Policy Notice (“Privacy
                    Notice”).</b>
                <br/><br/>
                This Privacy Notice set out:<br/><br/>
                <ul>
                    <li>What information we collect about you & how we use it</li>
                    <li>Our promotional updates and communications</li>
                    <li>Who we may share your information with</li>
                    <li>Where we store your information</li>
                    <li>How we protect your information</li>
                    <li>How long we keep your information</li>
                    <li>Your rights</li>
                    <li>Changes to this policy</li>
                    <li>How to contact us</li>
                    <li>Cookies and other technologies</li>
                </ul> */}
            </div>
        </section>
        {/* <section className="terms-section">
            <div className="title">What Information We Collect and How We Use It</div>
            <div className="content">
                We may collect information from you and automatically via our Site.<br/><br/>
                We explain what categories of information we collect (and how) in this section and what we do with each
                of the categories in the next section.<br/><br/>
                <b>Information You Give Us:</b><br/><br/>
                The information we learn from users helps us personalize and continually improve your Brandon TV
                experience. We explain what we collect and why we use it here (and we explain what each legal reason
                under “We do this:” below)<br/><br/>
                To manage our relationship with you, including:<br/><br/>
                <ul>
                    <li>providing our services to you</li>
                    <li>administering your account with us (if you create one)</li>
                    <li>storing your personal information</li>
                    <li>sending you service updates (including security alerts)</li>
                    <li>notifying you about changes to our terms or privacy policy</li>
                    <li>notifying you of any changes to our Services.</li>
                </ul>
                We use your:<br/><br/>
                <ul>
                    <li>Name</li>
                    <li>Email address</li>
                    <li>Phone number</li>
                </ul>
                We do this:<br/><br/>
                <ul>
                    <li>with your consent</li>
                    <li>in our legitimate interests</li>
                </ul>
                To personalize your experience and offer you relevant recommendations, to deliver relevant advertising
                and marketing to you, and for measuring and understanding the effectiveness of advertising we serve to
                you and others.<br/><br/>
                We use your:<br/><br/>
                <ul>
                    <li>Name</li>
                    <li>Email address</li>
                    <li>Phone number</li>
                </ul>

                We also use your:<br/><br/>

                · Technical information
                · Information about your visit & Location data (see below for more detail)

                We do this:<br/><br/>
                <ul>
                    <li>with your consent</li>
                    <li>in our legitimate interests</li>
                </ul>
                To report a problem with our site<br/><br/>
                We use your:<br/><br/>
                <ul>
                    <li>Name</li>
                    <li>Email address</li>
                    <li>Phone number</li>
                </ul>
                We do this:<br/><br/>
                <ul>
                    <li>with your consent</li>
                    <li>in our legitimate interests</li>
                </ul>
                <b>Information we automatically collect about you from your use of our services</b><br/><br/>
                We will automatically collect information from you each time you visit our Services:<br/><br/>
                <ul>
                    <li>To administer our services and for internal operations, including troubleshooting, data
                        analysis, testing, research, statistical and survey purposes.
                    </li>
                    <li>To improve our services to ensure that content is presented in the most effective manner for you
                        and for your computer.
                    </li>
                    <li>To keep our Services safe and secure.</li>
                </ul>
                We use your:<br/><br/>
                <ul>
                    <li>Technical information</li>
                    <li>Information about your visit & Location data</li>
                </ul>
                Technical information may include the Internet protocol (IP) address used to connect your computer to
                the Internet, your login information, browser type and version, time zone setting, browser plug-in types
                and versions, operating system and platform;<br/><br/>
                Information about your visit may include the full Uniform Resource Locators (URL), clickstream to,
                through and from our site (including date and time), products you viewed or searched for, page response
                times, download errors, length of visits to certain pages, page interaction information (such as
                scrolling, clicks, and mouse-overs), methods used to browse away from the page, and any phone number
                used to call our customer service number or social media handle used to connect with our customer
                service team.<br/><br/>
                Location data may include information through the site as to your real time location to provide location
                services where requested or agreed to by you in order to deliver content, advertising or other services
                that are dependent on knowing where you are. This information may also be collected in combination with
                an identifier associated with your device to enable us to recognize your mobile browser or device when
                you return to the site.<br/><br/>
                Delivery of location services will involve reference to one or more of the following: (a) the
                coordinates (latitude/longitude) of your location; (b) look-up of your country of location by reference
                to your IP address against public sources; and/or (c) your Identifier for Advertisers (IFA) code for
                your Apple device, or the Android ID for your Android device, or a similar device identifier.<br/><br/>
                See Cookies and other technologies for more information on the use of cookies and device identifiers on
                the site.<br/><br/>
                We do this:<br/><br/>
                <ul>
                    <li>with your consent</li>
                    <li>in our legitimate interests</li>
                </ul>

                <b>Information from Other Sources:</b><br/><br/>
                This is information that we receive about you from other sources (including third parties we work
                closely with) and add it to our account information. We are working closely with third parties
                (including, for example, business partners, subcontractors in technical and payment services,
                advertising networks, analytics providers and search information providers).<br/><br/>
                <ul>
                    <li>Legal bases (or 'Why we do this') explained</li>
                </ul>
                <b>Consent:</b> We'll use your personal information to send you promotional or marketing content if you
                have given us consent to us doing so, where required by law.<br/><br/>
                You can opt-out of further marketing at any time by selecting the “unsubscribe” link at the end of all
                our promotional updates and marketing to you.<br/><br/>
                We also rely on consent for some of the cookies we use (see our Cookies Policy for more
                detail).<br/><br/>
                <b>Contract:</b> We collect, store and process your personal information where it is necessary for
                performing a contract you have with us (such as our Terms and Conditions), or where you have asked us to
                take specific steps before entering into that contract. This includes notifying you about changes to our
                services and organization.<br/><br/>
                <b>Legal Obligation:</b> We may need to process your personal information to comply with our legal
                obligations, including under applicable local, UK and EU law, and/or any court orders.<br/><br/>
                <b>Legitimate Interests:</b> We may process your personal information if it is necessary for our
                legitimate interests or the legitimate interests of a third party, provided those interests are not
                outweighed by your rights and interests.<br/><br/>
                Our legitimate interests are:
                <ul>
                    <li>Enforcing or applying our terms or other agreements with you.</li>
                    <li>Providing you with the information, products and services that you request from us.</li>
                    <li>To allow you to participate in any interactive features of our Site, when you choose to do so.
                    </li>
                    <li>Gaining insights into how customers use our services; delivering, developing and improving our
                        services, and growing our business and informing our marketing strategy.
                    </li>
                    <li>Providing you with our promotional updates and marketing if we reach out to you and/or you are
                        interacting with us (or in certain cases if you have purchased a service from us and have not
                        opted-out at the time of purchase or any time since) (you are free to opt-out at any time).
                    </li>
                    <li>Providing you and our other customers with personalized recommendations, promotional updates and
                        marketing to improve your experience with our services.
                    </li>
                    <li>For measuring or understanding the effectiveness of advertising we serve to you and others, and
                        to deliver relevant advertising to you.
                    </li>
                    <li>To keep our services safe and secure.</li>
                    <li>To improve our Site to ensure that content is presented in the most effective manner for you and
                        for your computer.
                    </li>
                    <li>To administer our Site and for internal operations, including troubleshooting, data analysis,
                        testing, research, statistical and survey purposes.
                    </li>
                </ul>
                In each case, these legitimate interests are only valid if they are not outweighed by your rights and
                interests.<br/><br/>
                We also use different types of cookies (including automatically collected information) on our services
                with your consent – we explain this in the Cookies section.
            </div>
        </section>
        <section className="terms-section">
            <div className="title">Our promotional updates and communications</div>
            <div className="content">
                Where permitted in our legitimate interests (explained below) or with your prior consent if required by
                law, we may send you marketing and promotional updates about our services, by email and SMS, if you have
                opted-in to receiving these.<br/><br/>
                We compare our user list to lists received from other companies, in aneffort to avoid sending
                unnecessary messages to our users. If you do not want to receive e-mail orother mail from us, please
                adjust your preferences. You can object to further marketing at any time by checking and updating your
                contact details within your account, or selecting the "unsubscribe" link at the end of all our marketing
                and promotional update communications to you.
            </div>
        </section>
        <section className="terms-section">
            <div className="title">Who Do We Share Your Information with</div>
            <div className="content">
                Information about our users is an important part of our business, and we are not in the business of
                selling it to others. We share user information only as described below and with affiliates of Brandon
                TV that either are subject to this Privacy Notice or follow practices at least as protective as those
                described in this PrivacyNotice.<br/><br/>
                <b>Affiliated Businesses:</b> We work closely with affiliated businesses to constantly improve the
                digital content offered to you.<br/><br/>
                <b>Third-Party Service Providers:</b> We may employ other companies and individuals to perform functions
                on our behalf. Examples include maintaining the mobile app and service, sending postal mail and e-mail,
                removing repetitive information from user lists, analyzing data, providing marketing assistance,
                providing search results and links (including paid listings and links), processing credit card payments,
                and providing customer service. They have access to personal information needed to perform their
                functions, but may not use it for other purposes.<br/><br/>
                <b>Promotional Offers:</b> Sometimes we send offers to selected groups of users on behalf of other
                businesses. When we do this, we do not give that business your name and address. If you do not want to
                receive such offers, please adjust your preferences.<br/><br/>
                <b>Business Transfers:</b> As we continue to develop our service, we might sell or buy subsidiaries, or
                business units. In such transactions, user information generally is one of the transferred business
                assets but remains subject to the promises made in any pre-existing Privacy Notice (unless, of course,
                the user consents otherwise). Also, in the unlikely event that Brandon TV, or substantially all of its
                assets are acquired, user information will of course be one of the transferred assets.<br/><br/>
                <b>Protection of Brandon TV and Others:</b> We release accounts and other personal information when we
                believe release is appropriate to comply with the law; enforce or apply our Conditions of Use and other
                agreements; or protect the rights, property, or safety of Brandon TV, our users, or others. This
                includes exchanging information with other companies and organizations for fraud protection and credit
                risk reduction. Obviously, however, this does not include selling, renting, sharing, or otherwise
                disclosing personally identifiable information from users for commercial purposes in violation of the
                commitments set forth in this Privacy Notice.<br/><br/>
                <b>With Your Consent:</b> Other than as set out above, you will receive notice when information about
                you might go to third parties, and you will have an opportunity to choose not to share the
                information.<br/><br/>
                <b>Stripe.com:</b> Credit card transactions are processed using Stripe.com, a secure third party payment
                processing company. When using your credit card to purchase our service, you may be redirected to their
                secure third party gateway in the form of an iframe popup. The information you provide for payment will
                be stored on their secure servers. We do not store any payment information on our servers for your
                protection.
            </div>
        </section>
        <section className="terms-section">
            <div className="title">Where we store your information</div>
            <div className="content">
                BRANDON TV MAY CONTRACT WITH A VARIETY OF THIRD PARTY SUPPLIERS, PROVIDERS, VENDORS, AND SOCIAL NETWORKS
                FROM TIME TO TIME, TO PROVIDE FOR OUR HOSTING, AUTHENTICATION, SERVING, STORAGE AND TELECOMMUNICATION
                NEEDS, ETC., INCLUDING WITHOUT LIMITATION STORAGE OF OUR USERS PERSONALLY IDENTIFIABLE INFORMATION.
                BRANDON TV SHALL NOT BE RESPONSIBLE OR LIABLE, AND HEREBY DISCLAIMS ALL RESPONSIBILITY AND LIABILITY,
                FOR CULPABLE (INCLUDING WITHOUT LIMITATION NEGLIGENT) ACTS OR OMISSIONS BY ITS THIRD PARTY
                SUPPLIERS/VENDORS.<br/><br/>
                The data that we collect from you may be transferred to, and stored at, a destination outside the
                European Economic Area ("EEA") that may not be subject to equivalent Data Protection Law.<br/><br/>
                Where your information is transferred outside the EEA, we will take all steps reasonably necessary to
                ensure that your data is subject to appropriate safeguards, such as relying on a recognized legal
                adequacy mechanism, and that it is treated securely and in accordance with this privacy
                policy.<br/><br/>
                We may transfer your personal information outside the EEA:
                <ul>
                    <li>In order to store it.</li>
                    <li>Where we are legally required to do so.</li>
                    <li>In order to facilitate the operation of our group of businesses, where it is in our legitimate
                        interests and we have concluded these are not overridden by your rights.
                    </li>
                </ul>
            </div>
        </section>
        <section className="terms-section">
            <div className="title">How We Protect Your Information</div>
            <div className="content">
                Unfortunately, the transmission of information via the internet is not completely secure, and while we
                do our best to protect your personal information, we cannot guarantee the security of data transmitted
                to our Site; any transmissions are at your own risk.
                <br/><br/>
                Brandon TV operates or leases secure data networks in the United States protected by industry standard
                firewall and password protection systems. Our security and privacy policies are periodically reviewed
                and enhanced as necessary, and only authorized individuals have access to the information provided by
                our Clients. Notwithstanding such measures, we cannot guarantee that our security measures will prevent
                our computers from being illegally accessed, and the individual information on them stolen or altered.
                <br/><br/>
                Your account is protected by a password for your privacy and security. You must prevent unauthorized
                access to your account and Personal Information by selecting and protecting your password appropriately
                and limiting access to your computer or device and browser by signing off after you have finished
                accessing your account.
                <br/><br/>
                Our site may, from time to time, contain links to external sites. We are not responsible for the privacy
                policies or the content of such sites.
            </div>
        </section>
        <section className="terms-section">
            <div className="title">How Long We Keep your Information</div>
            <div className="content">
                We retain personal data you provide to us for as long as necessary in order to meet our legal
                obligations to you, and for six years after that to identify any issues and resolve any legal
                proceedings. We may also retain aggregate information beyond this time for research purposes and to help
                us develop and improve our services. You cannot be identified from aggregate information retained or
                used for these purposes.
                <br/><br/>
                We will retain personal data collected from you through the use of cookies and related technologies for
                the duration of your browsing session on the site.
            </div>
        </section>
        <section className="terms-section">
            <div className="title">Your Rights</div>
            <div className="content">
                You have the right under certain circumstances:
                <ul>
                    <li>to be provided with a copy of your personal information;</li>
                    <li>to request the correction or deletion of your personal information (you can delete your account
                        after signing in to Site);
                    </li>
                    <li>to request that the restriction the processing of your personal information (while your concerns
                        are investigated or verified, for example);
                    </li>
                    <li>to object to the further processing of your personal information; and</li>
                    <li>to request that your provided personal information be moved to a third party.</li>
                </ul>
                In particular, if the processing of your personal information is based on consent, you have the right to
                withdraw that consent without detriment at any time.
                <br/><br/>
                If your request or concern is not satisfactorily resolved by us, you may approach your local data
                protection authority, (see <a
                href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.html" target="_blank"
                rel="noreferrer">http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.html</a> ). The
                Information Commissioner is the supervisory authority in the UK and can provide further information
                about your rights and our obligations in relation to your personal data, as well as deal with any
                complaints that you have about our processing of your personal data.
            </div>
        </section>
        <section className="terms-section">
            <div className="title">Changes to This Policy</div>
            <div className="content">
                Any changes that we make to this privacy policy in the future will be posted on this page. If you choose
                to visit Brandon TV, your visit and any dispute over privacy is subject to this Notice and our Terms and
                Conditions, including limitations on damages, resolution of disputes, and application of the law of the
                state of California. If you have any concern about privacy, please contact us with a thorough
                description, and we will try to resolve it. Our business changes constantly, and our Privacy Notice and
                Conditions of Use will change also. We may e-mail periodic reminders of our notices and conditions, but
                you should check our website and/or mobile app frequently to see recent changes.<br/><br/>
                Unless stated otherwise, our current Privacy Notice applies to all information that we have about you
                and your account. We stand behind the promises we make, however, and will never materially change our
                policies and practices to make them less protective of user information collected in the past without
                the consent of affected users.
                <br/><br/>
                This policy was last updated on July 1, 2023.
            </div>
        </section>
        <section className="terms-section">
            <div className="title">What About Cookies?</div>
            <div className="content">
                Cookies are unique identifiers that we transfer to your device to enable our systems to recognize your
                device and to provide recommendations and personalized advertisements.<br/>
                The Help feature on most browsers will tell you how to prevent your browser from accepting new cookies,
                how to have the browser notify you when you receive a new cookie, or how to disable cookies altogether.
                Additionally, you can disable or delete similar data used by browser add-ons, such as Flash cookies, by
                changing the add-on's settings or visiting the website of its manufacturer.<br/>
                Because cookies allow you to take advantage of some of Brandon TV's essential features, we recommend
                that you leave them turned on.<br/><br/>
                <b>Disabling Cookies:</b> The effect of disabling cookies depends on which cookies you disable but, in
                general, the website may not operate properly if all cookies are switched off.<br/><br/>
                If you want to disable cookies on our website, you need to change your website browser settings to
                reject cookies. How you can do this will depend on the browser you use.<br/><br/>

                <b>Microsoft Internet Explorer</b>
                <ol>
                    <li>Select the Tools menu > Internet Options</li>
                    <li>Click on the Privacy tab</li>
                    <li>Click on Advanced within the Settings section and select the appropriate setting</li>
                </ol>

                <b>Google Chrome</b>
                <ol>
                    <li>Select Settings > Advanced</li>
                    <li>Under Privacy and Security > Content settings.</li>
                    <li>Click Cookies and select the relevant option</li>
                </ol>

                <b>Safari</b>
                <ol>
                    <li>Select Preferences > Privacy</li>
                    <li>Click on Remove all Website Data</li>
                </ol>

                <b>Mozilla Firefox</b>
                <ol>
                    <li>Choose the Tools menu > Options</li>
                    <li>Click on the Privacy icon</li>
                    <li>Select the Cookie menu and select the relevant options</li>
                </ol>

                <b>Opera 6.0 and further</b>
                <ol>
                    <li>Choose Files menu > Preferences</li>
                    <li>Select Privacy</li>
                </ol>
                Where you have not set your permissions, we may also separately prompt you regarding our use of cookies
                on the Site.
            </div>
        </section>
        <section className="terms-section">
            <div className="title">Are Children Allowed to Use Brandon TV?</div>
            <div className="content">
                Brandon TV provides a service for adults. If you are under 18, you may use Brandon TV only with the
                involvement of a parent or guardian. We do not knowingly collect personal information from children
                under the age of 13 without the consent of the child's parent or guardian.
            </div>
        </section> */}
    </div>
)

export default TermsContent
