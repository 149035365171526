import BtvLogoLong from "../assets/brandon-tv-logo.svg";
import { config } from "../config";

const activeLink = 'is-active';

const Header = ({ isActive, noCircle = false, hasAuth = false }) => (
    <header className={`header ${noCircle ? 'no-circle' : ''}`}>
        <div className="main-container">
            <div className="brandon-logo-container">
                <a href="/" className="link"><img src={BtvLogoLong} alt="Brandon TV Logo" className="img"/></a>
            </div>
            <div className="menu has-active">
                <a className="link" href={config.links.stream}>stream</a>
                <a className={`link ${isActive === 'contacts' ? activeLink : ''}`} href={config.routing.contacts}>
                    Contact us
                </a>
                <a className={`link`} href={config.links.blog}>OBSESSED</a>
                <a className={`link ${isActive === 'faq' ? activeLink : ''}`} href={config.routing.helpCenter}>
                    Help center
                </a>
            </div>
            {hasAuth ?
                <div className="register-block">
                    <a href={config.links.streamRegistration}
                       className="btn ghost register-login"
                    >Sign in / Sign up</a>
                </div>
                : ''}
        </div>
    </header>
)

export default Header
