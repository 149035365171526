import Header from "../../components/Header";
import Footer from "../../components/Footer";
import HelpCenterContent from "./HelpCenterContent";
import useDocumentTitle from "../../hooks/useDocumentTitle";

const HelpCenterPage = () => {
  useDocumentTitle('Help Center')

  return (
      <div className="help-center-page">
          <Header isActive={'faq'}/>
          <HelpCenterContent/>
          <Footer/>
      </div>
  )
}

export default HelpCenterPage
