import React from 'react';
import FaqData from './FaqData';
import FaqInfo from './FaqInfo';

const HelpCenterContent = () => {

  return (
    <div className="main-container page-content">
      <div className="title">HELP CENTER</div>
      <div className="help-center-section">
        {FaqData.map(
            (item, index) => <FaqInfo key={index} itemData={item} />
        )}
      </div>
    </div>
  )
}

export default HelpCenterContent
