import React, { useState } from 'react';

const FaqInfo = ({ itemData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const {title, content} = itemData
  const toggleFaq = () => setIsOpen(isOpen => !isOpen)

  return (
    <div className={`faq-container ${isOpen ? 'open' : ''}`}>
      <div 
      className="faq-title"
      onClick={toggleFaq}>
        {title}
      </div>
      <div
      className="faq-content"
      dangerouslySetInnerHTML={{ __html: content }}></div>
    </div>
  )
}

export default FaqInfo
