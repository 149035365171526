const FaqData = [
    {
        title: 'How do I subscribe to Brandon TV on the website or mobile apps?',
        content: `<p>If you are not already a subscriber and want to subscribe to Brandon TV, follow these steps:</p>
        <p>Register or Sign in to Brandon TV. After that go to <a class="colored-link" href="https://itsbrandontv.live/info-subscriptions">https://itsbrandontv.live/info-subscriptions</a> or click play on any video on the platform and then:</p>
      <ul>
        <li>Choose a billing period.</li>
        <li>Add promocode to get a discount (if you have one).</li>
        <li>Add your payment method.</li>
        <li>Choose Subscribe.</li>
      </ul>
       <p>Now you're ready to stream.</p>
    
      <p>Once you've signed up, you can sign in and stream Brandon TV on all your favorite devices. Your subscription will auto-renew on a recurring basis until you cancel.</p>`
    },
    {
        title: 'How do I subscribe to Brandon TV on Roku?',
        content: `
             <p>Using the Brandon TV Roku channel, you can watch our videos on your TV. Follow these steps to subscribe right within the channel:</p>
      <ul>
        <li>Add the Brandon TV Roku channel to your Roku.</li>
        <li>Open the Brandon TV Roku channel on your Roku and click Subscribe.</li>
        <li>Select Monthly or Annual (when available).</li>
        <li>Enter your payment information and confirm the subscription.</li>
      </ul>
        <p>You will then be able to watch immediately.</p>
      <p>Your subscription will auto-renew on a recurring basis at the then-current subscription rate (plus applicable taxes) until you cancel it.</p>
      <p>Once you've signed up, you can sign in and stream Brandon TV on all your favorite devices. Your subscription will auto-renew on a recurring basis until you cancel.</p>
        `
    },
    {
        title: 'If I signed up for BTV through Roku, how can I log in to the website or mobile apps?',
        content: `<p>If you registered through Roku, you should receive an email with a temporary password that can be used across all BTV platforms automatically.</p>`
    },
    {
        title: 'How do I cancel my subscription?',
        content: `<p>Open Brandon TV on your device and then:</p>
      <ul>
        <li>Tap your profile (upper right) and choose Wallet.</li>
        <li>Find your active pass and choose Unsubscribe.</li>
        <li>Confirm your cancellation and take note of your expiration date.</li>
      </ul>`
    },
    {
        title: 'How do I cancel my Roku subscription?',
        content: `
      <ul>
        <li>Press the Home button on your Roku remote.</li>
        <li>Use the directional pad on your remote to highlight Brandon TV.</li>
        <li>Press the Star button (*) to open the Options menu.</li>
        <li>Choose Manage subscription then Cancel subscription. If Manage subscription is not available, try these same steps on each Roku streaming device you own.</li>
      </ul>
      <p>You should get an email from Roku confirming your cancellation. You can continue streaming until your subscription expires at the end of your billing cycle.</p>
      <p>For more information you can visit the Roku support page dedicated to managing subscriptions using the following link: <a class="colored-link" href="https://support.roku.com/en-gb/article/208756478" target="_blank" rel="noreferrer">Roku Support - Cancel a Subscription</a>.</p>`
    },
    {
        title: 'How do I log into Roku TV if I didn\'t originally subscribe through Roku?',
        content: `
            <p>Roku TV Console:</p>
            <ul>
                <li>Turn on the Roku streaming device and check the email you're using for it. This email will be linked with your Brandon TV account.</li>
                <li>❗️If you'd like to use a different email, you'd have to reset the system in the console settings❗️</li>
            </ul>
            
            <p>Brandon TV Channel:</p>
            <ul>
                <li>Launch Brandon TV Channel</li>
                <li>Open navigation and select “LOG OUT” button</li>
                <li>Choose “Log out"</li>
            </ul>
            
            <p>Merge Account:</p>
            <ul>
                <li>Launch “Brandon TV” Channel</li>
                <li>Approve permission to use your email (Roku system)</li>
                <li>Select “No” on the screen showing your Roku email</li>
                <li>* If you see this error message after clicking “No”, you should select “Yes” because the accounts have already been merged previously (addition step 4):</li>
            </ul>
            
            <p>Addition Steps<br/>
            Select “Yes” if:</p>
            <ul>
              <li>You are a new BTV user with no account;</li>
              <li>You have an existing BTV account which has been previously connected to the Roku email shown on the screen;</li>
              <li>You have an existing BTV account and logged ONLY into Roku before (no website and apps).</li>
            </ul>
            
            <p>Connection</p>
            <ul>
              <li>Use your mobile phone camera to scan the QR or open this link manually (<a href="https://itsbrandontv.live/roku/" class="colored-link" target="_blank" rel="noreferrer">https://itsbrandontv.live/roku/</a>)</li>
              <li>Log into your main Brandon TV account (the one you registered through a website or mobile app), tap the code from the screen and select “Connect”</li>
              <li>You will be automatically logged in on your Roku Device.</li>
              <li>Read the information and select “Go to Main Feed”</li>
              <li>Don't forget to check your email with login and password for <a href="https://itsbrandontv.live" class="colored-link" rel="noreferrer" target="_blank">itsbrandontv.live</a> and mobile apps.</li>
            </ul>
        `
    },
    {
        title: 'How do I connect a Smart TV?',
        content: `
        <ul>
          <li>Open the <a href="https://play.google.com/store/apps/details?id=brandontv.app" target="_blank" rel="noreferrer" class="colored-link">Brandon TV app</a> on your smart TV.</li>
          <li>You'll see an option that says "Login with QR Code" or similar. Select this option.</li>
            <li>A QR code will appear on your TV screen.</li>
            <li>Using your phone's camera, scan the QR code displayed on your TV screen. This will automatically open your phone's web browser.</li>
            <li>Your phone's web browser will redirect you to the Brandon TV website.</li>
            <li>Login to your Brandon TV account using your phone's web browser.</li>
            <li>Once logged in, your phone will prompt you to open the Brandon TV app if you have it downloaded. Ignore this prompt and stay on the browser.</li>
            <li>In your browser, you'll see a page that says "Go to App" or "Connect TV." Select "Connect TV."</li>
            <li>Enter the code displayed on your TV screen into your phone's browser.</li>
            <li>Your smart TV should now be successfully connected to Brandon TV.</li>
        </ul>
        `
    },
    {
        title: 'How do I connect more than one TV?',
        content: 'Brandon TV currently allows only one TV to be connected, but we are working on adding more options and will announce when this is completed.'
    },
    // {
    //     title: 'How can I stream on Amazon Fire Stick or Samsung TV?',
    //     content: `
    //     <p>We’re currently only available on TVs which support Google Play. If you’re using such a device, here’s a link - <a href="https://play.google.com/store/apps/details?id=brandontv.app" class="colored-link" target="_blank" rel="noreferrer">https://play.google.com/store/apps/details?id=brandontv.app</a></p>
    //
    //     <p>Samsung and Amazon are not available for now.</p>
    //
    //     <p>However, we are excited to announce that we are actively working on making our app compatible with Fire Stick, and it will be available in the near future.</p>
    //     <p>In the meantime, we encourage you to continue enjoying Brandon TV on other supported devices, including smartphones, tablets, smart TVs, and streaming devices.</p>
    //     `
    // },
    {
        title: 'What other shows are coming to Brandon TV?',
        content: 'We are taking your favorite reality tv personalities queens, and bringing them back to television in a big way.'
    },
    {
        title: 'What is Brandon TV?',
        content: 'Brandon TV, also known as BTV, globally capitalizes on the growing entertainment market through the production and distribution of interactive new media entertainment. As a multi-channel network and digital media conglomerate, BTV has earned more than 16 billion views worldwide, entertaining 100 million subscribers across 63 new media channels. Our new streaming app is a place bringing gamified experiences to fans within the niche reality tv demographic.'
    },
    // {
    //     title: 'How can I get my subscription paid for?',
    //     content: 'During our early promotion, if you invite 2 friends that subscribe using your promotional link (please explain here where they will be able to find their link), you will get 1 month for free.'
    // },
    {
        title: 'How do I audition for Shine?',
        content: 'We are coming up on our 4th season of Shine. Find more information about auditions on our social media accounts at @itsbrandontv and @shinesings. We are also launching our first few spin-offs of the hit series including: Shine Juniors and Shine Divas.'
    }
];

export default FaqData;
